import React, { useState } from 'react'
import { contact } from '../../messages.js'
import loading from './loading.svg'
import SocialMedia from '../SocialMedia'

import './style.scss'

const fieldRequirements = {
	contactName: {
		required: true,
	},
	email: {
		regex: new RegExp('[^@]+@[^.]+..+'),
		required: true,
	},
	comments: {
		required: true,
	},
}

export default ({ lang }) => {
	const [fieldValues, setFieldValues] = useState({
		//contactName: '',
		//email: '',
		//comments: '',
	})

	const [fieldValidities, setFieldValidities] = useState({
		//contactName: true,
		//email: true,
		//comments: true,
	})

	const [sendingStatus, setSendingStatus] = useState(null)

	const handleChangeField = (evt) => {
		setFieldValues({
			...fieldValues,
			[evt.target.name]: evt.target.value,
		})
	}

	const handleFieldBlur = (evt) => {
		const { name, value } = evt.target
		const valid = checkFieldValidity(name, value)
		setFieldValidities({
			...fieldValidities,
			[name]: valid,
		})
	}

	const checkFieldValidity = (fieldName, fieldValue) => {
		const requirements = fieldRequirements[fieldName]
		if (requirements.required && fieldValue.length < 1) {
			return false
		}
		if (requirements.regex) {
			return requirements.regex.test(fieldValue)
		}
		return true
	}

	const validateForm = () => {
		const currentValidities = {}
		let formIsValid = true

		Object.keys(fieldValidities).forEach((fieldKey) => {
			const fieldIsValid = checkFieldValidity(
				fieldKey,
				fieldValues[fieldKey]
			)
			formIsValid = formIsValid && fieldIsValid
		})

		if (!formIsValid) {
			setFieldValidities(currentValidities)
		}
		return formIsValid
	}

	const submit = () => {
		if (!validateForm()) return null
		const data = {
			email: fieldValues.email,
			name: fieldValues.contactName,
			feedback: fieldValues.comments,
		}
		setSendingStatus('sending')
		fetch(
			'https://contact-us-mpoi-website.azure-api.net/mpoi-contact-form/mpoi-contact',
			{
				method: 'POST',
				mode: 'cors',
				cache: 'no-cache',
				headers: {
					'Content-Type': 'application/json',
				},
				redirect: 'follow',
				referrer: 'no-referrer',
				body: JSON.stringify(data),
			}
		)
			.then((res) => {
				if (res.ok) {
					console.log('done')
					setSendingStatus('sent')
					return
				}
				setSendingStatus('error')
				console.log(res)
			})
			.catch((err) => {
				setSendingStatus('error')
				console.log(err)
			})
	}

	return (
		<section>
			<div>
				<div className='text-content'>
					<h1>
						{contact[lang].title}
						<span className='green-text'>.</span>
					</h1>
					<h2>{contact[lang].questionsTitle}</h2>
					<p
						className='body-1'
						dangerouslySetInnerHTML={{
							__html: contact[lang].questionsText,
						}}
					/>
					<h2>{contact[lang].feedbackTitle}</h2>
					<p className='body-1'>{contact[lang].feedbackText}</p>
					<p
						className='body-1'
						dangerouslySetInnerHTML={{
							__html: contact[lang].email,
						}}
					/>

					{sendingStatus === 'error' && (
						<span className='error'>{contact[lang].errorText}</span>
					)}
					{sendingStatus === 'sent' && (
						<p className='body-1'>
							<strong>{contact[lang].sentText}</strong>
						</p>
					)}
					{
						/*
                    //10.28.2020 Removed Contact Form.
					{sendingStatus !== 'sent' && (
                         
						<div className='form'>
							<p className='body-1'>{contact[lang].formText}</p>
							<input
								name='contactName'
								placeholder={contact[lang].inputName}
								value={fieldValues.contactName}
								type='text'
								onChange={handleChangeField}
								onBlur={handleFieldBlur}
								className={
									fieldValidities.contactName
										? 'input'
										: 'input error'
								}
							></input>
							<input
								name='email'
								type={contact[lang].inputEmail}
								placeholder='Email'
								value={fieldValues.email}
								onChange={handleChangeField}
								onBlur={handleFieldBlur}
								className={
									fieldValidities.email
										? 'input'
										: 'input error'
								}
							></input>
							<textarea
								className='input'
								name='comments'
								placeholder={contact[lang].inputComments}
								rows='20'
								value={fieldValues.comments}
								onChange={handleChangeField}
								onBlur={handleFieldBlur}
								className={
									fieldValidities.comments
										? 'input'
										: 'input error'
								}
							></textarea>
							<button
								style={{ minWidth: '170px', maxHeight: '52px' }}
								className='btn green'
								onClick={submit}
							>
								{sendingStatus === 'sending' ? (
									<img src={loading} />
								) : (
									contact[lang].submit
								)}
							</button>
                        </div>
                        {*/
						//)
					}
					<h2>{contact[lang].socialTitle}</h2>
					<p
						className='body-1'
						dangerouslySetInnerHTML={{
							__html: contact[lang].socialText,
						}}
					/>
					<SocialMedia />
				</div>
			</div>
		</section>
	)
}
