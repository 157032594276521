import React from 'react'
import './style.scss'

export default props => (
    <div className='benefit'>
        <img src={props.img} />
        <div>
            <h3>{props.h3}</h3>
        </div>

        <p>{props.p}</p>
    </div>
)
