import React from 'react'
import Slider from 'react-slick'
import Link from 'gatsby-link'
import Step from '../Step'

import stepImg1 from './using-my-proof-step-1.svg'
import stepImg2 from './using-my-proof-step-2.svg'
import stepImg3 from './using-my-proof-step-3.svg'

import lock from '../../assets/lock_outline.svg'
import { how } from '../../messages.js'

class Steps extends React.Component {
    constructor(props) {
        super(props)
    }
    shouldComponentUpdate = (nextProps, nextState) => {
        if (this.props.current !== nextProps.current) {
            this.slider.slickGoTo(nextProps.current - 1)
            return false
        }
        return false
    }
    render = () => {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: this.props.onChange
        }
        const lang = this.props.lang
        const stepsContent = getStepContent(lang)
        return (
            <div
                className='stepsWrapper'
                style={{
                    width: '100%',
                    position: 'relative'
                }}
            >
                <Slider {...settings} ref={el => (this.slider = el)}>
                    <div>
                        <Step
                            title={stepsContent[0].title}
                            p={stepsContent[0].paragraph}
                            img={stepImg1}
                            stepNum='1'
                            lang={lang}
                        />
                    </div>
                    <div>
                        <Step
                            title={stepsContent[1].title}
                            p={stepsContent[1].paragraph}
                            img={stepImg2}
                            stepNum='2'
                            lang={lang}
                        ></Step>
                    </div>
                    <div>
                        <Step
                            title={stepsContent[2].title}
                            p={stepsContent[2].paragraph}
                            img={stepImg3}
                            stepNum='3'
                            lang={lang}
                        >
                            <div
                                className='lock'
                                style={{
                                    position: 'absolute'
                                }}
                            >
                                <img src={lock} />
                            </div>
                        </Step>
                    </div>
                </Slider>
            </div>
        )
    }
}

const getStepContent = lang => {
    const content = {
        en: [
            {
                title: 'Contact your insurance provider',
                paragraph: (
                    <p>
                        Ask your provider to have your policy documents sent by
                        email with My Proof of Insurance.
                    </p>
                )
            },
            {
                title: 'Get your documents',
                paragraph: (
                    <p>
                        Open your My Proof of Insurance email and save the
                        attached documents to your computer or device.
                        <br />
                        Click the button to download your auto insurance cards
                        to your smartphone's{' '}
                        <Link to='/#wallets'> digital wallet.</Link>
                    </p>
                )
            },

            {
                title: 'Protect your privacy',
                paragraph: (
                    <p>
                        Your smartphone allows you to lock your screen when
                        displaying your auto insurance card, restricting access
                        to other apps, personal information, and blocking
                        notifications of calls and text messages.
                        <br />
                        <br />
                        See how to lock your phone{' '}
                        <Link to='/#privacy'>here</Link>.
                    </p>
                )
            }
        ],
        fr: [
            {
                title: 'Communiquez avec votre fournisseur d’assurance.',
                paragraph: (
                    <p>
                        Demandez à votre fournisseur d’assurance de vous faire
                        parvenir vos documents d’assurance par courriel au moyen
                        de Ma preuve d’assurance.
                    </p>
                )
            },
            {
                title: 'Obtenez vos documents.',
                paragraph: (
                    <p>
                        Ouvrez votre courriel Ma preuve d’assurance et
                        sauvegardez les documents qui y sont joints dans votre
                        ordinateur ou votre appareil.
                        <br />
                        Cliquez sur le bouton pour télécharger votre carte
                        d’assurance automobile dans le{' '}
                        <a href='/#portefeuille'>portefeuille numérique</a> de
                        votre téléphone intelligent.
                    </p>
                )
            },

            {
                title: 'Protégez votre vie privée.',
                paragraph: (
                    <p>
                        Votre téléphone intelligent vous permet de verrouiller
                        votre écran lors de l’affichage de votre carte
                        d’assurance automobile, de restreindre l’accès à
                        d’autres applications et à vos renseignements
                        personnels, et de bloquer les avis d’appels et de
                        messages textes.
                        <br />
                        <br />
                        <a href='/#vie-privee'>Cliquez ici</a> pour savoir
                        comment verrouiller votre téléphone.
                    </p>
                )
            }
        ]
    }
    return content[lang]
}

export default Steps
