import React from 'react'
import Quote from './quote'
import './style.scss'

export default ({ img, text, person }) => (
    <div className='testimonial'>
        <div>
            <p className='body-1'>{text}</p>
            <br />
            <div className='person-container'>
                <img src={img} />
                <span className='body-1'>&mdash; {person}</span>
            </div>
        </div>
    </div>
)
