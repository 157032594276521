import React, { Component } from 'react'
import './style.scss'
import phoneImg from './mobile-blank-screen.png'

class Step extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    getPercent = array => {
        const percent = (1 / array.length) * 100
        return percent === 100 ? 'auto' : percent + '%'
    }

    render = () => {
        const {
            title,
            p,
            img,
            size,
            left,
            small,
            children,
            stepNum,
            lang
        } = this.props
        const percent = this.getPercent(img)
        return (
            <div className='step-wrapper' ref={el => (this.container = el)}>
                <div ref={el => (this.container = el)} className='step-content'>
                    <div className='step-column'>
                        <div className='step-image-container'>
                            <img src={phoneImg} />
                            <img className='step-image-icon' src={img} />
                        </div>
                        <div>{children && children}</div>
                    </div>
                    <div className='step-column'>
                        <span className='big-text'>
                            {lang === 'fr' ? 'Étape' : 'Step'} {stepNum}
                        </span>
                        <h3>{title}</h3>
                        <div className='body-2'>{p}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Step
