import React from 'react'
import Hero from '../../components/Hero'
import What from '../../components/What'
import Wave from '../../components/Wave'
import How from '../../components/How'
import Wallets from '../../components/Wallets'
import Privacy from '../../components/Privacy'
import Logo from '../../components/Logo'
import LogoFr from '../../components/Logo/french.js'
import Faq from '../../components/Faq'
import GetIt from '../../components/Get-it'
import Contact from '../../components/Contact'
import Disclaimer from '../../components/Disclaimer'
import Benefits from '../../components/Benefits'
import Testimonials from '../../components/Testimonials'
import { menu, hero } from '../../messages.js'

import '../style.scss'

class Home extends React.Component {
    constructor(props) {
        super(props)
        this.rectTops = {}
        this.currSection = ''
        this.menu = menu[props.lang || 'en']
    }

    shouldComponentUpdate = () => {
        return false
    }

    componentDidMount = () => {
        //window.addEventListener('scroll', this._scrollCheck)
    }

    componentWillUnmount = () => {
        //window.removeEventListener('scroll', this._scrollCheck)
    }

    _scrollCheck = () => {
        const windowHeight = window.innerHeight
        const currScroll = windowHeight + window.pageYOffset
        const offset = 300
        const detection = 30

        const what = this.what.getBoundingClientRect().top - offset
        const how = this.how.getBoundingClientRect().top - offset
        const wallet = this.wallet.getBoundingClientRect().top - offset
        const privacy = this.privacy.getBoundingClientRect().top - offset
        const getIt = this.getIt.getBoundingClientRect().top - offset
        const testimonials =
            this.testimonials.getBoundingClientRect().top - offset
        const faq = this.faq.getBoundingClientRect().top - offset
        const contact = this.contact.getBoundingClientRect().top - offset
        const about = this.about.getBoundingClientRect().top - offset

        var section = ''
        if (about <= detection) {
            section = this.menu.about.id
        } else if (contact <= detection) {
            section = this.menu.contact.id
        } else if (faq <= detection) {
            section = this.menu.faq.id
        } else if (testimonials <= detection) {
            section = this.menu.testimonials.id
        } else if (getIt <= detection) {
            section = this.menu.getIt.id
        } else if (privacy <= detection) {
            section = this.menu.privacy.id
        } else if (wallet <= detection) {
            section = this.menu.wallets.id
        } else if (how <= detection) {
            section = this.menu.how.id
        } else if (what <= detection) {
            section = this.menu.what.id
        }
        if (section !== this.currSection) {
            this.props.updateParent(`${section}`)
        }
        this.currSection = section
    }
    render = () => {
        const props = this.props
        const lang = props.lang || 'en'
        const localizedText = hero[lang]
        const LocalizedLogo = lang === 'en' ? Logo : LogoFr
        return (
            <div ref={el => (this.body = el)}>
                <Hero lang={lang} />
                <div ref={el => (this.what = el)}>
                    <What lang={lang} />
                </div>
                <Benefits lang={lang} />
                <div ref={el => (this.how = el)}>
                    <How lang={lang} />
                </div>
                <div ref={el => (this.wallet = el)}>
                    <Wallets lang={lang} />
                </div>

                <div ref={el => (this.privacy = el)}>
                    <Privacy lang={lang} />
                </div>

                <div ref={el => (this.getIt = el)}>
                    <GetIt lang={lang} />
                </div>

                <div
                    id={this.menu.testimonials.id}
                    ref={el => (this.testimonials = el)}
                >
                    <Testimonials lang={lang} />
                </div>
                <div ref={el => (this.faq = el)}>
                    <Faq lang={lang} />
                </div>
                <div id={this.menu.contact.id} ref={el => (this.contact = el)}>
                    <Contact lang={lang} />
                </div>
                <Disclaimer lang={lang} />
            </div>
        )
    }
}

export default Home
