import React, { useState } from 'react'
import Link from 'gatsby-link'
import FaqItem from './FaqItem'
import { menu, faq } from '../../messages.js'
import './style.scss'

export default ({ lang }) => {
    const [selected, setSelected] = useState(null)

    const select = newSelect => {
        if (selected === newSelect) {
            setSelected(null)
            return
        }
        setSelected(newSelect)
    }

    return (
        <section id={menu[lang].faq.id} className='faq-section'>
            <div>
                <div className='text-content'>
                    <h1>
                        {faq[lang].title}
                        <span className='green-text'>.</span>
                    </h1>
                    <div className='faq-container'>
                        {faq[lang].contents.map((faq, i) => (
                            <FaqItem
                                selected={selected}
                                select={select}
                                heading={faq.heading}
                                key={i}
                            >
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: faq.text
                                    }}
                                />
                            </FaqItem>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}
