import React from 'react'
import './style.scss'
import android from '../../assets/android-logo.svg'
import apple from '../../assets/apple-logo.svg'
import Arrow from './arrow'
import { menu, privacy } from '../../messages.js'
import { Link } from 'gatsby'
export default ({ ...props }) => {
    const localizedChildren = getChildComponents(props.lang)
    const privacyText = privacy[props.lang]
    return (
        <section id={menu[props.lang].privacy.id} className='privacy-container'>
            <div>
                <div className='text-content'>
                    <h1>
                        {privacyText.title}
                        <span className='green-text'>.</span>
                    </h1>
                    <div className='body-1'>{localizedChildren.intro}</div>
                    <div className='privacy-cards-container'>
                        <div>
                            <img src={apple} />
                            <h2>iPhone</h2>
                            <Link to='/apple-privacy'>{privacyText.apple}</Link>
                        </div>
                        <div>
                            <img src={android} />
                            <h2>Android</h2>
                            <Link to='/android-privacy'>
                                {privacyText.android}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const getChildComponents = lang => {
    return {
        en: {
            intro: (
                <p>
                    Your smartphone allows you to lock your screen when
                    displaying your auto insurance card, restricting access to
                    other apps, personal information, and blocking notifications
                    of calls and text messages. Here’s how to set it up:
                </p>
            ),
            ios: (
                <ol>
                    <span>Enable</span>
                    <li>
                        To enable screen locking, go to Settings <Arrow />{' '}
                        General <Arrow /> Accessibility. Turn on Guided Access
                        and Accessibility Shortcut.
                    </li>
                    <li>
                        Go to Passcode Settings to set your passcode and
                        enable/disable Touch ID. (For iPhone X, you may
                        enable/disable Face ID).{' '}
                    </li>
                    <span>Lock Screen</span>
                    <li>
                        Follow the instructions on your screen to use Guided
                        Access and lock your screen at any time.
                    </li>
                </ol>
            ),
            android: (
                <ol>
                    <span>Enable</span>
                    <li>
                        To enable screen locking, navigate to Screen Pinning
                        (also known as Pin Windows) in your phone’s settings.
                        The location of Screen Pinning/Pin Windows in settings
                        will vary between phone model and Android version.
                        Common locations include:
                        <ul>
                            <li>
                                Settings <Arrow /> Security
                            </li>
                            <li>
                                Settings <Arrow /> Personal <Arrow /> Security
                            </li>
                            <li>
                                Settings <Arrow /> Security & Location
                            </li>
                            <li>
                                Settings <Arrow /> Lock Screen & Security
                            </li>
                        </ul>
                    </li>
                    <li>
                        Turn on Screen Pinning/Pin Windows, and activate the
                        option to require passcode/unlock pattern before
                        unpinning.
                    </li>
                    <span>Lock Screen</span>
                    <li>
                        Follow the instructions on your screen to activate
                        Screen Pinning/Pin Windows when displaying your auto
                        insurance card. Your phone is now locked your digital
                        wallet preventing access to personal information,
                        message notifications and other apps.
                    </li>
                </ol>
            ),
            microsoft: (
                <p>
                    Locking feature removed from Windows phones as of software
                    update version 1703 released in April, 2017
                </p>
            )
        },
        fr: {
            intro: (
                <p>
                    Votre téléphone intelligent vous permet de verrouiller votre
                    écran lors de l’affichage de votre carte d’assurance
                    automobile, de restreindre l’accès à d’autres applications
                    et à vos renseignements personnels, et de bloquer les avis
                    d’appels et de messages textes. Voici la marche à suivre:
                </p>
            ),
            ios: (
                <ol>
                    <span>Activation</span>
                    <li>
                        Pour activer le verrouillage d’écran, allez dans
                        Réglages <Arrow /> Général <Arrow /> Accessibilité.
                        Activez la fonctionnalité Accès guidé et Raccourci
                        accessibilité.
                    </li>
                    <li>
                        Allez dans les Réglages Codes pour configurer votre code
                        d’accès et activer ou désactiver la fonctionnalité Touch
                        ID. (Dans l’iPhone X, vous pouvez activer ou désactiver
                        Face ID.)
                    </li>
                    <span>Verrouillage d’écran</span>
                    <li>
                        Lorsque vous affichez votre carte d’assurance
                        automobile, cliquez trois fois sur le bouton Home pour
                        activer la fonctionnalité Accès guidé. Votre téléphone
                        sera ainsi verrouillé et votre portefeuille numérique
                        empêchera l’accès à vos renseignements personnels et à
                        vos avis, messages et autres applications.
                    </li>
                </ol>
            ),
            android: (
                <ol>
                    <span>Activation</span>
                    <li>
                        Pour activer le verrouillage d’écran, accédez à la
                        fonctionnalité Épinglage d’écran (ou Épingler les
                        fenêtres) dans les paramètres de votre téléphone.
                        L’emplacement de la fonctionnalité Épinglage
                        d’écran/Épingler les fenêtres dans les paramètres
                        varient selon le modèle de téléphone et la version
                        d’Android. Les emplacements les plus courants sont les
                        suivants:
                        <ul>
                            <li>
                                Paramètres <Arrow /> Sécurité
                            </li>
                            <li>
                                Paramètres <Arrow /> Personnel <Arrow />{' '}
                                Sécurité
                            </li>
                            <li>
                                Paramètres <Arrow /> Sécurité et localisation
                            </li>
                            <li>
                                Paramètres <Arrow /> Écran verrouillé et
                                sécurité.
                            </li>
                        </ul>
                    </li>
                    <li>
                        Activez la fonctionnalité Épinglage d’écran/Épingler les
                        fenêtres et choisissez l’option qui permet de demander
                        la saisie d’un mot de passe pour annuler l’épinglage.
                    </li>
                    <span>Verrouillage d’écran</span>
                    <li>
                        Suivez les instructions à l’écran pour activer la
                        fonctionnalité Épinglage d’écran/Épingler les fenêtres
                        lorsque vous affichez votre carte d’assurance
                        automobile. Votre téléphone sera ainsi verrouillé et
                        votre portefeuille numérique empêchera l’accès à vos
                        renseignements personnels et à vos avis, messages et
                        autres applications.
                    </li>
                </ol>
            ),
            microsoft: (
                <p>
                    La fonction de verrouillage a été retirée des téléphones
                    Windows depuis la mise à jour du logiciel 1703 en avril
                    2017.
                </p>
            )
        }
    }[lang || 'en']
}
