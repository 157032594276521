import React from 'react'
import './style.scss'
import android from '../../assets/android-logo.svg'
import apple from '../../assets/apple-logo.svg'
import Microsoft from './microsoft'

import pass2u from './pass2u.png'
import appleWallet from './apple-wallet.png'
import walletPasses from './wallet-passes.png'

import google_play_fr from '../../assets/google_play_fr.png'
import { menu, wallets } from '../../messages.js'
const size = '36pt'

class What extends React.Component {
    constructor(props) {
        super(props)
    }
    render = () => {
        const localizedChildren = getChildComponents(this.props.lang)
        return (
            <section
                id={menu[this.props.lang].wallets.id}
                className='wallet-section'
            >
                <div>
                    <div className='text-content'>
                        <h1>
                            {wallets[this.props.lang].title}
                            <span className='green-text'>.</span>
                        </h1>
                        <div className='body-2'>{localizedChildren.intro}</div>

                        <div>
                            <div className='wallets-container'>
                                {localizedChildren.ios}
                                {localizedChildren.android}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

const getChildComponents = lang => {
    return {
        en: {
            intro: (
                <div>
                    <p>
                        Mobile digital wallets are available on all smartphones
                        and serve as an electronic version of a physical wallet.
                    </p>
                    <p>
                        With My Proof of Insurance, you can store auto insurance
                        cards in your digital wallet alongside credit and debit
                        cards, loyalty cards, event tickets, and boarding
                        passes. Common digital wallets:
                    </p>
                </div>
            ),
            ios: (
                <div>
                    <img src={appleWallet} />
                    <div>
                        <h4>
                            Apple Wallet <img src={apple} />
                        </h4>
                        <p>
                            Standard on iPhones running iOS 6 or higher.
                            (Pre-Installed)
                        </p>
                    </div>
                </div>
            ),
            android: [
                <div key='pass2U'>
                    <img src={pass2u} />
                    <div>
                        {' '}
                        <h4>
                            Pass2U <img src={android} />
                        </h4>
                        <p>
                            Available for <strong>free</strong> in Google Play
                            store for devices running Android 3.0 or higher.
                        </p>
                    </div>
                </div>,
                <div key='walletPasses'>
                    <img src={walletPasses} />
                    <div>
                        <h4>
                            WalletPasses <img src={android} />
                        </h4>
                        <p>
                            Available for <strong>free</strong> in Google Play
                            store for devices running Android 4.0.3 or higher.
                        </p>
                    </div>
                </div>
            ],
            microsoft: (
                <div>
                    <Microsoft size={size} />
                    <h4>Microsoft Wallet</h4>
                    <p>
                        Windows phone running Windows 10 and higher.*
                        (Pre-Installed)
                    </p>
                    <small>
                        *Compatible handset required; check with your carrier
                        for availability
                    </small>
                </div>
            )
        },
        fr: {
            intro: (
                <div>
                    <p>
                        Les portefeuilles numériques mobiles sont disponibles
                        sur tous les téléphones intelligents et sont la version
                        électronique d’un portefeuille physique.
                    </p>
                    <p>
                        Grâce à Ma preuve d’assurance, vous pouvez stocker des
                        cartes d’assurance automobile dans votre portefeuille
                        numérique tout comme vos cartes de crédit et de débit,
                        cartes de programmes de fidélisation, billets
                        d’événements et cartes d’embarquement. Portefeuilles
                        numériques courants:
                    </p>
                </div>
            ),
            ios: (
                <div>
                    <img src={appleWallet} />
                    <div>
                        <h4>
                            Wallet Apple <img src={apple} />
                        </h4>
                        <p>
                            Standard sur les téléphones iPhone équipés d’un
                            système iOS 6 ou modèle ultérieur (préinstallé).
                        </p>
                    </div>
                </div>
            ),
            android: [
                <div key='pass2U'>
                    <img src={pass2u} />
                    <div>
                        {' '}
                        <h4>
                            Pass2U <img src={android} />
                        </h4>
                        <p>
                            Offert <strong>gratuitement</strong> dans le magasin
                            Google Play pour les appareils dotés d’un système
                            Android 3.0 ou modèle ultérieur.
                        </p>
                    </div>
                </div>,
                <div key='walletPasses'>
                    <img src={walletPasses} />
                    <div>
                        <h4>
                            WalletPasses <img src={android} />
                        </h4>
                        <p>
                            Offert <strong>gratuitement</strong> dans le magasin
                            Google Play pour les appareils dotés d’un système
                            Android 4.0.3 ou modèle ultérieur.
                        </p>
                    </div>
                </div>
            ],
            microsoft: (
                <div>
                    <Microsoft size={size} />
                    <h4>Wallet de Microsoft</h4>
                    <p>
                        Téléphone Windows fonctionnant avec Windows 10 et plus
                        récent.* (préinstallé)
                    </p>
                    <small>
                        *Appareil compatible requis; vérifiez la disponibilité
                        auprès de votre fournisseur
                    </small>
                </div>
            )
        }
    }[lang || 'en']
}

export default What
