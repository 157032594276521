import React from 'react'
import Steps from './steps'
import { menu, how } from '../../messages.js'
import videoSplash from '../../assets/video-splash.png'
import './style.scss'

class How extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			step: 1,
		}
	}

	changeStep = (step) => {
		this.setState({
			step,
		})
	}
	onScrollChange = (current, next) => {
		this.setState({
			step: next + 1,
		})
	}

	render = () => {
		const videoId =
			this.props.lang === 'en' ? '6096569668001' : '6096579463001'
		return (
			<section className='how-section' id={menu[this.props.lang].how.id}>
				<div>
					<div className='text-content'>
						{/*
                        <h1>
                            {how[this.props.lang].title}
                            <span className='green-text'>.</span>
                        </h1>*/}
					</div>
					{/*
						<iframe
							src={`https://players.brightcove.net/2591610666001/default_default/index.html?videoId=${videoId}`}
							allowfullscreen=''
							allow='encrypted-media'
							width='960'
						></iframe>
					*/}
					<div className='text-content'>
						<h2>{how[this.props.lang].stepsTitle}</h2>
					</div>
					<div>
						<Steps
							onChange={this.onScrollChange}
							current={this.state.step}
							lang={this.props.lang}
						/>
					</div>
				</div>
			</section>
		)
	}
}

export default How
