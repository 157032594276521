import React, { Component } from 'react'
import Slider from 'react-slick'
import Quote from './quote'
import Testimonial from './testimonial'
import { testimonials } from '../../messages.js'
import aaronSvg from './testimonials-aaron.svg'
import carlosSvg from './testimonials-carlos.svg'
import danaSvg from './testimonials-dana.svg'
import jenniferSvg from './testimonials-jennifer.svg'
import yasminSvg from './testimonials-yasmin.svg'

import './style.scss'
const settings = {
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
}

const svgObject = {
    Aaron: aaronSvg,
    Carlos: carlosSvg,
    Dana: danaSvg,
    Jennifer: jenniferSvg,
    Yasmin: yasminSvg
}

const findSvg = person => svgObject[person.split(',')[0]]

export default class Testimonials extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const localizedMessages = testimonials[this.props.lang]
        return (
            <section className='testimonials-container'>
                <div>
                    <div className='text-content'>
                        <h1>
                            {localizedMessages.title}
                            <span className='green-text'>.</span>
                        </h1>
                        <Slider {...settings}>
                            {localizedMessages.contents.map(
                                (testimonial, i) => (
                                    <div key={i}>
                                        <Testimonial
                                            text={testimonial.text}
                                            person={testimonial.person}
                                            img={findSvg(testimonial.person)}
                                        />
                                    </div>
                                )
                            )}
                        </Slider>
                    </div>
                </div>
            </section>
        )
    }
}
