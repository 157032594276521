import React from 'react'
import carret from './right-arrow.svg'

export default ({ select, selected, heading, children }) => (
    <div className={`faq-item${selected === heading ? ' selected' : ''}`}>
        <h2 className='h4' onClick={() => select(heading)}>
            {heading} <img src={carret} />
        </h2>
        <div className='body-3'>{selected === heading && children}</div>
    </div>
)
