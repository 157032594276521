import React from 'react'
import Card from '../../components/BenefitCard'
import { hero, benefits } from '../../messages.js'
import easySvg from './benefits-easy.svg'
import freeSvg from './benefits-free.svg'
import onTheGoSvg from './benefits-on-the-go.svg'
import paperlessSvg from './benefits-paperless.svg'
import secureSvg from './benefits-private-secure.svg'
import './style.scss'

import mobile1 from '../../assets/hero/mobile-1.png'
import mobile2 from '../../assets/hero/mobile-2.png'
import mobile3 from '../../assets/hero/mobile-3.png'

import mobile1fr from '../../assets/hero/fr/mobile-1.png'
import mobile2fr from '../../assets/hero/fr/mobile-2.png'
import mobile3fr from '../../assets/hero/fr/mobile-3.png'

class Benefits extends React.Component {
	render = () => {
		const { title, onTheGo, secure, paperless, free, easy } = benefits[
			this.props.lang
		]
		const { name } = hero[this.props.lang]
		return (
			<section>
				<div>
					<div className='text-content'>
						<h1>
							{title}
							<br />
							{name}
							<span className='green-text'>?</span>
						</h1>
					</div>
					<div className='benefits-section'>
						<Card
							h3={secure.title}
							p={secure.text}
							img={secureSvg}
						/>
						<Card
							h3={onTheGo.title}
							p={onTheGo.text}
							img={onTheGoSvg}
						/>
						<Card
							h3={paperless.title}
							p={paperless.text}
							img={paperlessSvg}
						/>
						<Card h3={easy.title} p={easy.text} img={easySvg} />
						<Card h3={free.title} p={free.text} img={freeSvg} />
					</div>
					<div className={'hero-images'}>
						<img
							src={this.props.lang === 'en' ? mobile1 : mobile1fr}
						/>
						<img
							src={this.props.lang === 'en' ? mobile2 : mobile2fr}
						/>
						<img
							src={this.props.lang === 'en' ? mobile3 : mobile3fr}
						/>
					</div>
				</div>
			</section>
		)
	}
}

export default Benefits
