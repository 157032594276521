import React from 'react'
import { hero, menu } from '../../messages'
import videoSplash from '../../assets/video-splash.png'
import { Link } from 'gatsby'
import './style.scss'

class Hero extends React.Component {
	constructor(props) {
		super(props)
	}

	render = () => {
		const videoId =
			this.props.lang === 'en' ? '6096569668001' : '6096579463001'
		return (
			<section className='hero'>
				<div classname='divider'>
					<div className='text-content'>
						<h6>
							{hero[this.props.lang].introducing}
							<span className='green-text'>.</span>
							<br />
							{/* {hero[this.props.lang].name} 
							<span className='green-text'>.</span>*/}
						</h6>
						<span className='body-5'>
							{hero[this.props.lang].intro}
							<br></br>
							{hero[this.props.lang].intro2}
						</span>
						<div>
							<Link
								to={`/#${menu[this.props.lang].getIt.id}`}
								className='btn green'
							>
								{hero[this.props.lang].getIt}
							</Link>
						</div>
					</div>
					{this.props.lang === 'en' &&
					<div class='video'>
						<iframe width="560" height="315" src="https://www.youtube.com/embed/zFG-41MmOsE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					</div> 
					}
				</div>
			</section>
		)
	}
}
export default Hero
