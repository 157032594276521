import React from 'react'
import { hero, what, menu } from '../../messages'
import stat1 from './what-is-stat-1.svg'
import stat2 from './what-is-stat-2.svg'
import './style.scss'

export default ({ lang }) => {
    return (
        <section id={menu[lang].about.id} className='section-background'>
            <div>
                <div className='text-content'>
                    <h1>
                        {what[lang].what}
                        <br />
                        {hero[lang].name}
                        <span className='green-text'>?</span>
                    </h1>
                    <div className='what-content'>
                        <div>
                            <p className='body-3'>
                                <strong>{hero[lang].name}</strong>{' '}
                                {what[lang].text1}
                            </p>
                            <p className='body-3'>{what[lang].text2}</p>
                        </div>
                        <div>
                            <div>
                                <img className='stats-img' src={stat1} />
                                <p className='stats-text'>
                                    <span className='green-text'>
                                        {what[lang].statGreenText1}
                                    </span>{' '}
                                    {what[lang].statText1}
                                </p>
                            </div>
                            <div>
                                <img className='stats-img' src={stat2} />
                                <p className='stats-text'>
                                    {what[lang].statText2a}{' '}
                                    <span className='green-text'>
                                        {what[lang].statGreenText2}
                                    </span>{' '}
                                    {what[lang].statText2b}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
