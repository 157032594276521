import React from 'react'
import Link from 'gatsby-link'
import './style.scss'
import { menu, getIt } from '../../messages.js'

const GetIt = ({ lang }) => (
    <section id={menu[lang].getIt.id} className='get-it-container'>
        <div>
            <div className='text-content'>
                <h1>
                    {getIt[lang].title}
                    <span className='blue-text'>.</span>
                </h1>
                <p className='body-1'>{getIt[lang].paragraph}</p>
            </div>
        </div>
    </section>
)

export default GetIt
