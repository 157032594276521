import React from 'react'
import { disclaimer } from '../../messages'
import './style.scss'

export default ({ lang }) => (
    <section className='disclaimer-section'>
        <div>
            <div className='text-container'>
                <span>{disclaimer[lang].title}</span> <br />
                <br />
                {disclaimer[lang].text}
            </div>
        </div>
    </section>
)
